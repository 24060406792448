  /* input */
  
  input,
  textarea {
    color: #0e3276;
  }
  
  .form-control:focus {
      border-color: #4caf50 !important;
      box-shadow: none !important;
  }
  
  textarea {
    resize: none;
  }
  
  textarea::placeholder {
    color: #a8b7ce;
  }
  
  input#search {
    width: 100%;
  }
  
  input {
    outline: none;
  }

  input::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  input::-moz-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  input:-moz-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  input:-ms-input-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  input:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  input:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  input:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  input:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  textarea::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  textarea::-moz-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  textarea:-moz-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  textarea:-ms-input-placeholder {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  textarea:focus::-webkit-input-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  textarea:focus::-moz-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  textarea:focus:-moz-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  textarea:focus:-ms-input-placeholder {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  /* end input */

