#newsPage {
    .item-news {
        .author-block {
            margin-top: 50px;
            padding: 20px;
            border: solid 1px $orange;
            display: flex;
        
            .author-img {
                max-width: 250px;
                flex-shrink: 0;
            }
        
            .author-content {
                width: calc(100% - 200px);
                padding-left: 20px;
        
                p.title {
                    margin-bottom: 15px;
                    font-weight: bold;
                    a {
                        margin-left: 15px;
                        font-weight: 400;
                    }
                }

                .last-content {
                    width: auto;
                    max-width: 450px;

                    p {
                        margin-bottom: 10px;
                        font-weight: bold;
                    }

                    ul {
                        margin: 0;
                        list-style-type: none;

                        li {
                            line-height: 1.3rem;

                            &::before {
                                content: '';
                            }

                            a {
                                width: 100%;
                                overflow: hidden;
                                display: inline-block;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                }                
            }
        
            .author-all_content {
                width: 220px;
                text-align: right;
            }

            a {
                color: $orange;
                font-size: 0.9rem;
                
                &:hover {
                    text-decoration: underline;    
                }
            }
        }
    }
}