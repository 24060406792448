#about {
    margin-bottom: 100px;

    h1 {
        margin-bottom: 30px;
    }

    h3 {}

    img {
        width: 100%;
        margin: 20px 0 40px;
    }

    p {
        margin: 20px 0;
    }
}