#events {
    margin-top: 50px;
    margin-bottom: 100px;

    h1 {
        color: #3c3c3c;
        margin-bottom: 50px;
    }

    .event {
        display: flex;
        width: 100%;
        background-color: #f5f5f5;
        margin: 20px 0;

        .e_date {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 50px;
            background-color: $orange;
            color: #fff;

            span {
                font-size: 3.5rem;
            }
        }

        .e_img {
            overflow: hidden;
            img {
                height: 100%;
            }
        }

        .e_content {
            display: flex;
            align-items: center;
            padding: 50px;


            .title {
                color: $orange;
                margin-bottom: 40px;

                a {
                    color: $orange;
                    font-size: 1.3rem;
                }
            }

            .ww {
                display: flex;
                justify-content: space-between;
                align-items: center;
                // min-width: 600px;
                font-size: 0.8rem;

                .time {
                    position: relative;
                    padding-top: 10px;
                    padding-right: 10px;
                    padding-bottom: 10px;
                    padding-left: 28px;
                    margin-right: 20px;

                    background-color: #e7e7e7;

                    &::before {
                        content: '';
                        position: absolute;
                        height: 11px;
                        width: 11px;
                        margin-left: -18px;
                        margin-top: 2px;
                        background-color: #3c3c3c;
                        mask-size: contain;
                        mask-position: center;
                        mask-repeat: no-repeat;
                        mask-image: url(/assets/template/images/icons/clock-regular.svg);
                    }
                }
                
                .where {
                    position: relative;
                    padding-top: 10px;
                    padding-right: 10px;
                    padding-bottom: 10px;
                    padding-left: 28px;

                    background-color: #e7e7e7;

                    &::before {
                        content: '';
                        position: absolute;
                        height: 11px;
                        width: 11px;
                        margin-left: -18px;
                        margin-top: 2px;
                        background-color: #3c3c3c;
                        mask-size: contain;
                        mask-position: center;
                        mask-repeat: no-repeat;
                        mask-image: url(/assets/template/images/icons/location-dot-solid.svg);
                    }
                }
            }
        }

        .e_btn {
            display: flex;
            align-items: center;
            padding: 50px;

            button {
                background-color: $orange;
                color: #fff;
                border: 1px solid $orange;
                padding: 10px 20px;
                cursor: pointer;
                transition: all .3s;

                &:hover {
                    background-color: transparent;
                    color: $orange;
                    transition: all .3s;
                }
            }
        }
    }
}

#event {
    margin-top: 50px;

    .e_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .e_date {
            position: relative;
            margin-left: 20px;

            &::before {
                content: '';
                position: absolute;
                height: 13px;
                width: 13px;
                margin-left: -18px;
                margin-top: 2px;
                background-color: #3c3c3c;
                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
                mask-image: url(/assets/template/images/icons/clock-regular.svg);
            }
        }

        .e_geopoint {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                height: 13px;
                width: 13px;
                margin-left: -18px;
                margin-top: 2px;
                background-color: #3c3c3c;
                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
                mask-image: url(/assets/template/images/icons/location-dot-solid.svg);
            }
        }

        .e_btn {
            display: flex;
            align-items: center;

            button {
                background-color: $orange;
                color: #fff;
                border: 1px solid $orange;
                padding: 10px 20px;
                cursor: pointer;
                transition: all .3s;

                &:hover {
                    background-color: transparent;
                    color: $orange;
                    transition: all .3s;
                }
            }
        }
    }

    .e_img {
        width: 100%;
        height: 300px;
        // background-color: #f5f5f5;
        margin-top: 20px;
    }

    h1 {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    p {
        margin-bottom: 20px;
    }
}