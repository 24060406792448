.breadcrumb-block {
    margin-top: 20px;
    // border-top: 1px solid #7a7a7a;
    padding-bottom: 10px;
    border-bottom:  1px solid #d0d0d0;
    width: 100%;

    ul.breadcrumb {
        display: flex;
        color: #7a7a7a;
        font-size: 13px;

        li {
            list-style-type: none;
            margin-right: 5px;
            // text-transform: lowercase;
            color: $orange;

            a {
                color: #7a7a7a;

                &:hover {
                    color: $orange;
                }
            }

            &::after {
                content: '>';
                margin-left: 8px;
                margin-right: 3px;
                color: #7a7a7a;
            }

            &:last-child {
                &::after {
                    content: ''
                }
            }
        }
    }
}