.banner {
	background-image: url(../images/banner/logus/background.png);
	width: 100%;
	height: 100px;

	a {
		&.logus {
			background-image: url(../images/banner/logus/banner_logus.png);
			background-repeat: no-repeat;
			width: 100%;
			height: 100px;
		}
	}
}