@import "./fonts.scss";
@import "./mobile.scss";

@import "./menuBtn.scss";

@import "./header.scss";
@import "./section_news.scss";
@import "./input.scss";
@import "./var.scss";
@import "./noselect.scss";
@import "./breadcrumbs.scss";
@import "./news_page.scss";
@import "./tags_page.scss";
@import "./right_block.scss";
@import "./video.scss";
@import "./footer.scss";

@import "./search.scss";
@import "./subscribe_modal.scss";

@import "./auth.scss";

@import "./401.scss";
@import "./404.scss";
@import "./503.scss";

@import "./modal_service_work.scss";

@import "./pagination.scss";
@import "./list.scss";

@import "./expert.scss";
@import "./experts.scss";
@import "./author.scss";

@import "./create_page.scss";
@import "./content.scss";

@import "./events.scss";
@import "./reg_modal_form.scss";

@import "./comments.scss";

@import "./about.scss";

@import "./banner.scss";

body,  * {
    padding: 0;
    margin: 0;
    font-family: 'Roboto';
    font-style: normal;
}

body, h1, h2, h3, h4, h5, h6, p, a {
    color: #3c3c3c;
}

a {
    text-decoration: none;
    transition: $transition;

    &:hover {
        transition: $transition;
        color: $orange;
    }
}

.container {
    width: 1100px;
    display: flex;
    margin: 0 auto;
}