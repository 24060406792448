#NEWS {

    &.pb100 {
        padding-bottom: 100px;
    }

    &.grey {
        background-color: #f7f7f7;
        margin-top: 50px;
        padding-top: 50px;
        padding-bottom: 100px;
        border-top: 1px solid #ccc;
        padding-bottom: 100px;

           .news_area {
                .news_blocks {
                    .news_block_item {
                        background-color: #fff;
                        border-bottom: 1px solid #eee;
                        border-right: 1px solid #eee;
                        min-height: 465px;

                        .nbi_title, 
                        .nbi_text, 
                        .nbi_row {
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }

                .news_row_item {
                    background-color: #fff;
                    border-right: 1px solid #eee;
                    padding-top: 15px;
                }
            }
    }


    .title {
        margin: 30px 0;

        a{
            color: #000000;
            font-size: 24px;

            &:hover {
                color: $orange;
            }
        }
    }

    .news_area {
        display: flex;
        justify-content: center;

        .news_blocks {
            display: flex;
            justify-content: left;
            align-items: center;
            flex-wrap: wrap;
            flex: 8;
    
            .news_block_item {
                width: 47%;
                min-height: 460px;
                margin: 10px;

                &:nth-child(odd){
                    margin-left: 0;
                }

                .nbi_image {
                    a{
                        width: 100%;
                        height: 250px;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        
                        img{
                            width: auto;
                            height: 100%;
                        }
                    }
                }

                .nbi_title {
                    height: 45px;
                    max-height:45px;
                    overflow: hidden;
                    padding-top: 15px;

                    a {
                        color: #000000;
                        font-size: 17px;
                        line-height:24px;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        font-weight: 600;

                        &:hover {
                            color: $orange;
                        }
                    }
                }

                .nbi_text {
                    padding: 15px 0 0 0;
                    height: 72px;
                    overflow: hidden;
                    line-height: 24px;
                    font-size: 14px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }

                .nbi_row {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 20px;

                    .nbi_category {
                        font-size: 12px;
                        font-weight: 500;
                        color: $orange;

                        a {
                            color: $orange;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .nbi_date {
                        font-size: 12px;
                    }
                }
            }
        }
    
        .news_rows {
            flex: 4;
            height: 50px;
            padding-top: 10px;

            .news_row_item {
                display: flex;
                flex-grow: 1;
                height: 90px;
                margin-top: 15px;
                border-bottom: solid 1px #eee;

                &:first-child {
                    margin-top: 0;
                }

                .nri_image {
                    // width: 100%;
                    height: 70px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: auto;
                        height: 100%;
                    }
                }

                .nri_data {
                    height: 68px;
                    max-height:68px;
                    padding-left: 15px;

                    .nrid_title {
                        height: 45px;
                        max-height:45px;
                        overflow: hidden;
                        margin-bottom: 10px;

                        a {
                            color: #000000;
                            font-size: 16px;
                            line-height:24px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            font-weight: 500;

                            &:hover {
                                color: $orange;
                            }
                        }
                    }

                    .nrid_date {
                        font-size: 12px;
                    }
                }

            }
        }
    }
}