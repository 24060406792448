@import "./var.scss";

header {
    .header_top {
        padding: 20px 0;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            // .logo {}

            .date {
                margin: 0 0 0 auto;
                padding: 7px;
                background-color: $orange;
                color: #fff;
                border-radius: 5px;
                border: 1px solid $orange;
                font-size: 24px;
                transition: $transition;
                cursor: pointer;

                &:hover {
                    background-color: #fff;
                    color: $orange;
                    transition: $transition;
                }
            }

            .subscribe {
                margin-left: auto;
                padding: 0 20px;
            }
            .search {
                width: 20px;
                height: 20px;
                padding: 0 20px;
                border-left: 1px solid #eee;
                border-right: 1px solid #eee;
            }
            .auth {
                padding: 0 20px;
            }
        }

    }
    .header_bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $orange;
        color: #fff;

        &.fixed {
            position: fixed;
            width: 100%;
            top: 0;
            left: 0;
            box-shadow: 0px 5px 8px 0px rgba(34, 60, 80, 0.2);
            z-index: 9;

            .logo {
                display: block;
                padding-top: 2px;
                padding-right: 30px;

                img {
                    height: 40px;
                    width: auto;
                }
            }

            .sb-search {
                display: block;
            }

            .icons_menu {
                display: flex;
                // width: 400px;
                margin-top: 4px;


                .subscribe,
                .auth {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 40px;
                    height: 40px;
                }

                a {
                    color: #fff;
                }
            }   
        }

        .logo {
            display: none;
        }

        .create {
            margin-top: 15px;

            a {
                color: #fff;
                text-decoration: none;
                position: relative;

                &::before {
                    display: block;
                    content: "";
                    background-color: #fff;
                    height: 1px;
                    width: 0%;
                    top: -9px;
                    left: 50%;
                    position: absolute;
                    -webkit-transition: width .2s ease-in-out;
                    -moz--transition: width .2s ease-in-out;
                    transition: width .2s ease-in-out;
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    transform: translateX(-50%);
                }

                &::after {
                    display: block;
                    content: "";
                    background-color: #fff;
                    height: 1px;
                    width: 0%;
                    bottom: -11px;
                    left: 50%;
                    position: absolute;
                    -webkit-transition: width .2s ease-in-out;
                    -moz--transition: width .2s ease-in-out;
                    transition: width .2s ease-in-out;
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    transform: translateX(-50%);
                }

                &:hover {
                    &::before {
                        width: 100%;
                    }
                    &::after {
                        width: 100%;
                    }
                }
            }
        }

        menu {
            flex: 1;
            ul {
                li {
                    display: inline-block;
                    padding: 15px;

                    &:first-child {
                        padding-left: 0;
                    }

                    a {
                        color: #fff;
                        text-decoration: none;
                        position: relative;

                        &::before {
                            display: block;
                            content: "";
                            background-color: #fff;
                            height: 1px;
                            width: 0%;
                            top: -9px;
                            left: 50%;
                            position: absolute;
                            -webkit-transition: width .2s ease-in-out;
                            -moz--transition: width .2s ease-in-out;
                            transition: width .2s ease-in-out;
                            -webkit-transform: translateX(-50%);
                            -moz-transform: translateX(-50%);
                            transform: translateX(-50%);
                        }

                        &::after {
                            display: block;
                            content: "";
                            background-color: #fff;
                            height: 1px;
                            width: 0%;
                            bottom: -11px;
                            left: 50%;
                            position: absolute;
                            -webkit-transition: width .2s ease-in-out;
                            -moz--transition: width .2s ease-in-out;
                            transition: width .2s ease-in-out;
                            -webkit-transform: translateX(-50%);
                            -moz-transform: translateX(-50%);
                            transform: translateX(-50%);
                        }

                        &:hover {
                            &::before {
                                width: 100%;
                            }
                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .icons_menu {
            display: none;
        }
    }
}