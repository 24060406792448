#content {
    // padding: 30px 0;

    h1 {
        padding: 50px 0;
    }

    p {
        padding: 15px 0;
    }
}