#tagsPage {
    margin-top: 20px;

    .tags-list {
        flex: 9;
        margin-right: 30px;

        .tag-item {
            display: flex;
            margin-top: 30px;
            padding-top: 30px;
            border-top: solid 1px #ccc;

            .item-img {
                img {
                    width: 100%;
                    min-width: 350px;
                }
            }

            .item-info {
                padding-left: 30px;

                .item-title {
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                .item-content {
                    height: 170px;
                    overflow: hidden;
                    line-height: 24px;
                    font-size: 14px;
                    display: -webkit-box;
                    -webkit-line-clamp: 6;
                    -webkit-box-orient: vertical;
                }

                .item-tags {
                    margin-top: 10px;
                    ul {
                        display: flex;
                        li {
                            list-style-type: none;
                            margin-right: 10px;

                            a {
                                font-size:12px;
                                color: $orange;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}