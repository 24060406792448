ul.pagination {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    li {
        list-style-type: none;
        padding: 5px 10px;
        margin-right: 1px;
        border: 1px solid $orange;
        background-color: $orange;
        
        &:hover {
            background-color: #fff;
            
            a {
                color: $orange;
            }
        }

        a {
            color: #fff;
        }
    }
}