#NEW__PAGE {
    padding: 50px 0;

    #ticketForm {
        width: 100%;

        .form-group {
            // width: 1100px;
            width: 100%;
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                // width: 130px;
                margin-right: 30px;
            }

            input {
                padding: 13px;
                border: 1px solid #f98653;
                width: 100%;
            }

            .markItUp {
                width: 770px;
            }

            textarea {
                width: 100%;
                padding: 13px;
                border: 1px solid #f98653;
            }
        }

        .form-actions {

            &.row {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .col-md-6 {
                    // width: 50%;
                }
            }
        }


        input {
            &.btn {
                // width: 100%;
                background-color: #f98653;
                color: #fff;
                border: 1px solid #f98653;
                padding: 13px 26px;
                cursor: pointer;
                transition: all 0.2s;

                &:hover {
                    color: #f98653;
                    background-color: #fff;
                    transition: all 0.2s;
                }
            }
        }
    }
}