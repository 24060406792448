#experts {
    margin-top: 20px;
    

    .experts-list {
        width: 100%;
        
        h1 {
            // margin-bottom: 20px;
        }

        .expert-item {
            border-bottom: 1px solid #eee;
            padding: 50px 0;
            display: flex;
            
            .expert-image {
                width: 400px;
                img {
                    width: 100%;
                }
            }

            .expert-info {
                width: calc(100% - 400px);
                padding-left: 30px;

                .expert-name {
                    font-size: 20px;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }

                .expert-contacts {
                    margin-bottom: 20px;
                }

                .expert-all-materials {
                    margin-bottom: 35px;

                    a {
                        text-decoration: underline;
                        color: $orange;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .expert-last-news {
                    
                    .item-news-info {
                        display: flex;
                        justify-content: space-between;
                        font-size: 12px;

                        a {
                            color: $orange;
                        }
                    }

                    .item-news-content {

                        a.title {
                            font-size:20px;
                            line-height: 60px;
                        }

                        p {
                            height: 52px;
                            overflow: hidden;
                            line-height: 24px;
                            font-size: 14px;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
        }
    }
    
}