@import "in_list.scss";

/* Lists */
.ticket-comment,
.ticket-comment-row {margin-bottom: 15px;}
.ticket-new-comments {color: green;}

/* Ticket form */
#ticketForm {max-width: 800px;}
#ticketForm #ticket-sections {max-width: 400px;}
#ticketForm .error, #comment-form .error {color: brown;}
#ticketForm [name="content"] {display:inline-block;}
#ticket-preview-placeholder {background: #fff; outline: 5px solid #AAA; padding: 5px; display: none; margin: 10px 0; overflow: hidden;}
#ticketForm .form-actions,
#comment-form .form-actions {margin-top: 10px; padding-top: 10px; border-top: 1px solid #e3e3e3;}
#ticketForm .form-actions .checkbox,
#comment-form .form-actions .checkbox {display:inline;}
#ticketForm .move-right {text-align: right;}

/* Comments */
.comments {position:relative;overflow:hidden;}
.comments-list {padding-left: 25px;}
.ticket-comment {list-style-type: none;margin-top:20px;}
.ticket-avatar {display:inline-block;}
.ticket-comment .ticket-avatar {max-width: 24px; max-height: 24px;}
.ticket-comment-author {display:inline-block;font-weight:bold;margin-left: 10px;}
.ticket-comment-createdon {display:inline-block;font-size: 0.8em;color: #555555;margin-left: 10px;}
.ticket-comment-edited {display:inline-block;font-size: 0.8em;color: #555555;margin-left: 10px;font-style:italic;}
.ticket-comment-link {display:inline-block; margin-left: 15px;}

.ticket-comment-header {position: relative;}
.ticket-comment:hover > .ticket-comment-body > .ticket-comment-header > .ticket-comment-dot-wrapper {display:block;}
.ticket-comment-dot-wrapper {display:none;position:absolute;top:0;left:0;width:1px;height:1px;}
.ticket-comment-dot {width:500px;height:5px;right:1px;top:9px;position:absolute;background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAFCAYAAACw/UeDAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3QUTDRYgxxbUHAAAAFVJREFUGNO1kbENACEMxBwKJCRmICOw/xgZIaxAgZSGb34E4gVOPsNPRFySEAAzu2stxhjMOeX1SImI6+703nF3zjnPjUqtVVSVvTeqSmtNsm5LbfIBwCcf3dkm2FsAAAAASUVORK5CYII=) repeat-x 100% 0;}

.ticket-comment-text {margin-top: 5px;}
.ticket-reply-link a {font-size: 0.8em; text-decoration: none; border-bottom: 1px dashed;}
.ticket-comment-down {display: none;}
/* Guest comment */
.ticket-comment-guest .ticket-comment-author {color: #AAA;}
/* Bad comments*/
.ticket-comment .bad .ticket-comment-text {
	-moz-transition: opacity 1s ease;
	-o-transition: opacity 1s ease;
	-webkit-transition: opacity 1s ease;
}
.ticket-comment .bad.bad5 .ticket-comment-text { opacity: 0.2;}
.ticket-comment .bad.bad4 .ticket-comment-text { opacity: 0.35;}
.ticket-comment .bad.bad3 .ticket-comment-text { opacity: 0.5;}
.ticket-comment .bad.bad2 .ticket-comment-text { opacity: 0.65;}
.ticket-comment .bad.bad1 .ticket-comment-text { opacity: 0.8;}
.ticket-comment .bad.bad:hover .ticket-comment-text {opacity: 1;}

#comment-editor {width: 99%; display:inline-block;}
#comment-preview-placeholder {background: #fff;border: 5px solid #AAA;padding: 5px;display: none;margin: 10px 0;overflow:hidden;}

/* Messages */
.tickets-message-success  {background-color: green !important;}
.tickets-message-error  {background-color: brown !important;}
.tickets-message-info  {background-color: black !important;}

/* Panel with spinner */
#comments-tpanel {width:32px;display: none;position:fixed;right:0;top:50%;margin-top: -16px;}

#tpanel {width:32px;position:fixed;top:50%;opacity:0.5;background: #AAA;}
#tpanel:hover{opacity:1;}

#tpanel .change{color:#666;font-size:12px;text-decoration:none;padding:0 10px;position:absolute;left:0;top:-15px}
#tpanel .divider{border-top: solid 1px #5c5c5c;border-bottom: solid 1px #dddddd;width: 28px;height: 0px;margin:0 auto;}
#tpanel .refresh{display:block;background:url("../../img/web/refresh.gif") no-repeat left top;width:28px;margin:2px;height:28px;background-size:32px;}
#tpanel .refresh.loading{background:url("../../img/web/loading.gif") no-repeat left top;background-size:32px;}
#tpanel .new{text-decoration:none;display: block;color:#fff;font-size:12px;padding:0;padding-top:4px;padding-bottom:6px;width:34px;text-align:center;}

#tpanel-refresh {opacity: .5;width: 32px;height: 32px;background-image: url('../../img/web/refresh.gif');background-size: 32px;cursor: pointer;}
#tpanel-refresh:hover {opacity: 1;}
#tpanel-refresh.loading {opacity: 1;background-image: url('../../img/web/loading.gif');cursor: default;}

#tpanel-new {margin-top: 2px;border-top: solid 1px #efefef;text-align: center;color: darkgreen;cursor: pointer;}

/* Unseen comment */
.ticket-comment-new .ticket-comment-header {background-color: #f5f5dc;}


/* Rating and vote */
.ticket-comment-rating {float: right; color: #555;}
.ticket-rating .rating, .ticket-comment-rating .rating {font-weight: bold;}

.ticket-rating.active .vote,
.ticket-comment-rating.active .vote {
	cursor: pointer;
}

.ticket-rating.active .vote.plus:hover,
.ticket-rating.inactive .vote.plus.voted,
.ticket-rating .rating.positive,
.ticket-comment-rating.active .vote.plus:hover,
.ticket-comment-rating.inactive .vote.plus.voted,
.ticket-comment-rating .rating.positive {
	color: green;
}

.ticket-rating.active .vote.minus:hover,
.ticket-rating.inactive .vote.minus.voted,
.ticket-rating .rating.negative,
.ticket-comment-rating.active .vote.minus:hover,
.ticket-comment-rating.inactive .vote.minus.voted,
.ticket-comment-rating .rating.negative {
	color: red;
}

.ticket-rating.inactive,
.ticket-comment-rating.inactive {
	color: #AAA;
}

.ticket-rating.active .vote.refrain:hover {
	opacity: .7;
}

/* Ticket stars*/
.ticket-star.active .star,
.ticket-comment-star.active .star {cursor: pointer;}
.ticket-star .stared,
.ticket-comment-star .stared {color: green;}
.ticket-star .unstared,
.ticket-comment-star .unstared {color: gray;}
.ticket-star.active .unstared:hover,
.ticket-comment-star.active .unstared:hover {color: green;}

/* Ticket meta*/
.ticket-meta, .section-meta {
	margin: 20px 0;
	padding: 20px 0;
	border-top: 1px solid #d3d3d3;
	border-bottom: 1px solid #d3d3d3;
}

/* Form files */
#ticket-files-container {margin-top: 10px;}
#ticket-files-select {font-weight: bold;}
#ticket-files-progress,
#ticket-files-list .ticket-file-template {display:none;}
#ticket-files-progress-count {}
#ticket-files-progress-percent {float:right;}
#ticket-files-progress-bar {height: 1.5em; background: lightblue; margin-top:5px; width: 0;}

.clearfix {clear: both;}
#ticket-files-list {padding: 10px 0; background: #fff; border: 1px solid #ccc; border-radius: 5px; min-height: 50px;}
#ticket-files-list .ticket-file {width: 120px; float:left; margin: 5px 0 5px 10px; outline: 1px solid #efefef;}
#ticket-files-list .ticket-file-image-wrapper {width: 120px; height: 90px; overflow:hidden;}
#ticket-files-list .ticket-file-image {width: 120px; border: 1px solid #efefef;}
#ticket-files-list .ticket-file-size {color: #777; float: right;}

#ticket-files-list.droppable {border-width: 3px; border-style: dashed;}
#ticket-files-list.dragover {border-color: darkgreen;}
#ticket-files-list .ticket-file-meta {font-size: .8em; padding: 0 5px;}

#ticket-files-list .ticket-file.new {outline-color: lightblue;}
#ticket-files-list .ticket-file.deleted {outline-color: darkred;}

#ticket-files-list .ticket-file .ticket-file-restore {color: darkgreen;}
#ticket-files-list .ticket-file .ticket-file-delete {color: darkred;}

#ticket-files-list .ticket-file .ticket-file-restore,
#ticket-files-list .ticket-file.deleted .ticket-file-delete {display: none;}
#ticket-files-list .ticket-file.deleted .ticket-file-restore {display: inline;}

#ticket-files-list .ticket-ghost-state-highlight {background: lightblue;}