#expert {
    margin-top: 30px;

    .expert-block {
        max-width: 360px;
        height: 100%;
        margin-right: 40px;
        border: 1px solid rgba(249, 134, 83, .3);
        background-color: rgba(249, 134, 83, .1);
        font-size: 14px;

        .item-title {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .expert-name {
            padding: 40px;
            border-bottom: 1px solid rgba(249, 134, 83, .3);

            .item-title {
                font-size: 18px;
            }

            .item-expert-info {
                display: flex;

                .item-img {
                    width: 150px;
                    margin-right: 20px;
                    img {
                        width: 100%;
                    }
                }

                .item-status {
                    width: calc(100% - 130px);
                }
            }
        }

        .expert-about {
            padding: 40px;
            border-bottom: 1px solid rgba(249, 134, 83, .3);

            .item-about-content {
                font-style: italic;
            }
        }

        .expert-contacts {
            padding: 40px;
            border-bottom: 1px solid rgba(249, 134, 83, .3);

            .item-contacts {
                display: flex;
                flex-direction: column;

                a {
                    height: 25px;
                    position: relative;
                    padding-left: 30px;

                    &::before {
                        content: "";
                        position: absolute;
                        width: 17px;
                        height: 17px;
                        left: 0;
                        background-color: $orange;
                        mask-size: contain;
                        mask-position: center;
                        mask-repeat: no-repeat;
                        margin-right: 17px;
                    }

                    &.email {
                        &::before {
                            mask-image: url('/assets/template/images/icons/mail.svg');
                        }
                    }

                    &.phone {
                        &::before {
                            mask-image: url('/assets/template/images/icons/phone.svg');
                        }
                    }
                }
            }
        }

        .expert-social {
            padding: 40px;

            .expert-social-list {
                display: flex;

                a {
                    width: 32px;
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background-color: $orange;
                    color: #fff;
                    margin-right: 7px;
                    border: 1px solid $orange;
    
                    &::after {
                        content: '';
                        height: 17px;
                        width: 17px;
                        background-color: #fff;
                        mask-size: contain;
                        mask-position: center;
                        mask-repeat: no-repeat;
                        margin-left: -3px;
                    }
    
                    &.telegram {
                        &::after {
                            mask-image: url(/assets/template/images/icons/telegram.svg);
                        }
                    }
    
                    &.vk {
                        &::after {
                            mask-image: url(/assets/template/images/icons/vk.svg);
                        }
                    }

                    &:hover {
                        background-color: #fff;
                        color: $orange;
                        

                        &::after {
                            background-color: $orange;
                        }
                    }
                }
            }
        }
    }

    .expert-news {
        width: calc(100% - 360px);

        .news-item {
            padding-bottom: 60px;
            margin-bottom: 50px;
            border-bottom: 1px solid $orange;

            .news-info {
                display: flex;
                justify-content: space-between;
                font-size:14px;
                margin-bottom:30px;

                .news-category {}

                .news-date {}
            }

            .news-title {
                margin-bottom: 20px;
                font-weight: 600;
            }

            .news-content {
                height: 72px;
                overflow: hidden;
                line-height: 24px;
                font-size: 14px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
}