#Page404 {
    width: 100%;
    margin-top: 100px;
    text-align: center;
    h1 {
        font-size: 7.5em;
        margin-bottom: 50px;
        color: $orange;
    }

    p {
        line-height: 1.9rem;
    }
}