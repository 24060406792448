/* Search icon by IcoMoon, made with http://icomoon.io/app/ */
$orange: #f98653;

@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot');
	src:url('../fonts/icomoon/icomoon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.woff') format('woff'),
		url('../fonts/icomoon/icomoon.ttf') format('truetype'),
		url('../fonts/icomoon/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

.sb-search {
	position: relative;
    display: none;
	margin-top: 4px;
	width: 0%;
	min-width: 40px;
	height: 40px;
	float: right;
	overflow: hidden;
	-webkit-transition: width 0.3s;
	-moz-transition: width 0.3s;
	transition: width 0.3s;
	-webkit-backface-visibility: hidden;
}

.sb-search-input {
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	outline: none;
	background: #fff;
	width: 100%;
	height: 40px;
	margin: 0;
	z-index: 10;
	padding: 20px 55px 20px 20px;
	font-family: inherit;
	font-size: 20px;
	color: #2c3e50;
}

.sb-search-input::-webkit-input-placeholder {
	color: $orange;
}

.sb-search-input:-moz-placeholder {
	color: $orange;
}

.sb-search-input::-moz-placeholder {
	color: $orange;
}

.sb-search-input:-ms-input-placeholder {
	color: $orange;
}

.sb-icon-search,
.sb-search-submit  {
	width: 40px;
	height: 40px;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	padding: 0;
	margin: 0;
	line-height: 40px;
	text-align: center;
	cursor: pointer;
}

.sb-search-submit {
	background: #fff; /* IE needs this */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
    filter: alpha(opacity=0); /* IE 5-7 */
    opacity: 0;
	color: transparent;
	border: none;
	outline: none;
	z-index: -1;
}

.sb-icon-search {
	color: #fff;
	background: $orange;
	z-index: 90;
	//font-size: 22px;
	font-size: 15px;
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
}

.sb-icon-search:before {
	content: "\e000";
}

/* Open state */
.sb-search.sb-search-open,
.no-js .sb-search {
	width: 300px;
}

.sb-search.sb-search-open .sb-icon-search,
.no-js .sb-search .sb-icon-search {
	background: $orange;
	color: #fff;
	z-index: 11;
}

.sb-search.sb-search-open .sb-search-submit,
.no-js .sb-search .sb-search-submit {
	z-index: 90;
}


#search-page {
	width: 100%;
	margin-top: 50px;
	margin-bottom: 50px;

	.search {
		label {
			width: 100%;
			font-weight: bold;
		}

		input#search {
			padding: 13px;
			margin-top: 15px;
			margin-bottom: 15px;
			margin-right: -5px;
			min-width: 300px;
			width: 300px;
			border: 1px solid #f98653;
		}
	
		.btn {
			background-color: #f98653;
			color: #fff;
			border: 1px solid #f98653;
			padding: 13px 020px;
			cursor: pointer;
			transition: $transition;
	
			&:hover {
				color: #f98653;
				background-color: #fff;
				transition: $transition;
			}
		}
	}

	h1 {
		margin-top: 50px;
		margin-bottom: 10px;
	}

	.search-item {
		padding: 30px 0;
		border-top: 1px solid #ccc;

		.item-title {
			margin-bottom:10px;
			font-weight: bold;
		}
	}
}

.search-top {
	background-color: rgba(249,134,83, 1);
	padding: 5px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 9;
	display: none;

	&.active {
		display: block;
	}

	.container {
		justify-content: center;
		align-items: center;
		height: 100vh;

		.close-btn {
			color: #fff;
			position: absolute;
			top: 7%;
			right: 7%;
			font-size: 36px;
			cursor: pointer;

			&::after {
				content: "\2716";
			
			}
		}
	}

	.search-block {

		input#search {
			padding: 13px;
			margin-right: -5px;
			min-width: 300px;
			width: 300px;
			border: 1px solid #fff;
		}

		.btn {
			background-color: $orange;
			color: #fff;
			border: 1px solid #fff;
			padding: 13px 020px;
			cursor: pointer;
			transition: $transition;
	
			&:hover {
				color: $orange;
				background-color: #fff;
				border-left: 1px solid $orange;
				transition: $transition;
			}
		}
	}
}