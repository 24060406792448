//1a1b1f

#video {
    background-color: #2b2b2b;
    color: #fff;
    padding: 50px 0 100px;

    .container {
        display: block;

        .plyr__control--overlaid, 
        .plyr__control:hover, 
        .plyr--video .plyr__control[aria-expanded=true]
         {
            background-color: $orange;
        }
        
        .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true] {
            &:hover {
                &::before {
                    background-color: #fff;
                }
            }
            
            &::before {
                background-color: $orange;
            }

            &::after {
                background-color: #fff;
            }
        } 

        .plyr__menu__container .plyr__control[role=menuitemradio] {
            &:hover {
                &::after {
                    background: $orange !important;
                    width: 6px !important;
                    height: 6px !important;
                }
            }
            // &::after {
            //     width: 6px;
            //     height: 6px;
            // }
        } 

        input[type=range] {
            color: $orange;
        }

        .title {
            font-size: 24px;
            margin-bottom: 40px;
        }
        
        .container-video {
            display: flex;

            .video {
                width: 70%;
                height: 450px;
            }
        
            .playlist {
                width: 30%;
                padding-left: 20px;
                color: #fff;
    
                p.playlist {
                    font-size: 24px;
                    padding: 0;
                    margin-bottom: 20px;
                }
    
                .playlist-item {
                    display: flex;
                    margin: 1px 0;
                    padding: 15px;
                    cursor: pointer;
                    transition: $transition;

                    &.active, &:hover {
                        background-color: #565656;
                        transition: $transition;
                    }

                    .item-img {
                        img {
                            width: 100px;
                        }
                    }
                    p {
                        margin: 0;
                        padding: 0;
                        margin-block-start: 0;
                        margin-block-end: 0;

                        &.item-title {
                            margin-left: 20px;
                            color: #fff;
                            font-size: 13px;
                        }
                    }

                }
            }
        }
    }
}