.reg_modal_form {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: #fff;
    opacity: 0;
    transition: all .3;

    &.visible {
        z-index: 1000;
        opacity: 1;
        transition: all .3;
    }

    .close {
        position: absolute;
        top: 5%;
        right: 5%;

        &::after {
            content: '';
            position: absolute;
            height: 30px;
            width: 30px;
            margin-left: -18px;
            margin-top: 2px;
            background-color: $orange;
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            mask-image: url(/assets/template/images/icons/close.svg);
            cursor: pointer;
            transition: all .3;
        }

        &:hover {
            &::after {
                filter: brightness(90%);
                transition: all .3;
            }
        }
    }

    form {
        width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -125px;
        margin-left: -200px;

        input {
            width: 100%;
            margin-bottom: 15px;
            padding: 15px;
            border: 1px solid $orange;
            // border-radius: 5px;
            font-size: 14px;
            color: #3c3c3c;

            &::placeholder {
                color: #ccc;
            }
        }

        button {
            background-color: $orange;
            border: 1px solid $orange;
            color: #fff;
            padding: 10px 20px;
            cursor: pointer;
            transition: all .3;

            &:hover {
                background-color: #fff;
                color: $orange;
                transition: all .3;
            }
        }
    }
}