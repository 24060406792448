.subscribe-modal {
    background-color: rgba(249,134,83, 1);
	padding: 5px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 9;
	display: none;

	&.active {
		display: block;
	}
    
    .container {
        justify-content: center;
		align-items: center;
		height: 100vh;

		.close-btn {
			color: #fff;
			position: absolute;
			top: 7%;
			right: 7%;
			font-size: 36px;
			cursor: pointer;

			&::after {
				content: "\2716";
			
			}
		}

        .subscribe-block {
            p {
                color: #fff;
                margin-bottom: 40px;
    
                &.header {
                    font-size: 24px;
                    margin-bottom: 25px;
                }
            }
    
            input {
                padding: 13px;
                margin-right: -5px;
                min-width: 300px;
                width: 300px;
                border: 1px solid #fff;
            }
    
            .btn {
                background-color: $orange;
                color: #fff;
                border: 1px solid #fff;
                padding: 13px 020px;
                cursor: pointer;
                transition: $transition;
        
                &:hover {
                    color: $orange;
                    background-color: #fff;
                    border-left: 1px solid $orange;
                    transition: $transition;
                }
            }
        }
    }
}