.right-block {
    flex: 3;

    .tags {
        margin: 20px 0;
        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: baseline;
            
            li {
                list-style-type: none;
                margin-right: 3px;
                font-size: 13px;
                
                &.title {
                    color: $orange;
                    font-size: 14px;
                }
                
                a {
                    font-style: italic;
                    color: #7a7a7a;

                    &::after {
                        content: ',';
                    }

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .subscribe {
        border: dotted 2px $orange;
        padding: 20px 0 30px;
        text-align: center;

        p.title {
            font-size: 24px;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 25px;
        }

        a {
            padding: 7px 14px;
            border: solid 1px $orange;
            background-color: $orange;
            color: #fff;

            &:hover {
                background-color: #fff;
                color: $orange;
            }
        }
    }

    .news-block {

        margin-top: 20px;

        p.title-news {
            font-weight: 700;
            font-size: 16px;
            color: #3c3c3c;
            margin: 0 0 20px;
            line-height: 20px;
        }

        .news-item {
            position: relative;
            margin: 20px 0;

            .item-news-image {
                
                background-repeat: no-repeat;
                background-size: cover;
                height: 175px;
                
                &::after {
                    content: '';
                    width: 100%;
                    display: block;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,#000 100%);
                    z-index: 1;
                    opacity: 1;
                    transition: all .3s ease;
                }
            }

            .item-news-title {
                position: absolute;
                bottom: 0;
                color: #fff;
                padding: 20px;
                z-index: 2;
            }
        }
    }
}