@media (max-width: 480px) {
    header {
        position: relative;
        height: 50px;
        z-index: 1;

        .header_mobile {
            height: 30px;
            width: 100%;
            padding: 10px 0;
            background-color: #f98653;
            position: fixed;
            display: flex;
            justify-content: space-between;

            menu {
                margin-left: 10px;
            }

            .logo {
                // text-align: center;
                padding-left: 50px;

                a {
                    img {
                        width: 20px;
                    }
                }
            }

            .icons_menu {
                display: flex;
                justify-content: right;
                align-items: center;
                margin-top: 2px;

                a {
                    color: #fff;
                    margin-right: 10px;
                }
            }
        }
    }


    
    .container {
        width: 95% !important;
        margin-left: 2.5% !important;
        margin-right: 2.5% !important;
    }

    .date {
        display: none !important;
    }

    .news_area {
        display: table-cell !important;
    }

    .news_blocks,
    .news_rows {
        flex: 12 !important;
    }

    .news_block_item {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;

        .nbi_image {
            img {   
                width: 100% !important;
                height: 100% !important;
                overflow: hidden !important;
                object-fit: cover !important;
            }
        }
    }


    .container-video {
        display: block !important;

        .video {
            width: 100% !important;
            height: auto !important;
        }

        .playlist {
            width: 100% !important;
            padding: 0 !important;
        }
    }

    .subscribe-form {
        input {
            width: 150px !important;
            min-width: 150px !important;
        }
    }

    #newsPage .container .item-news {
        flex: 12 !important;
        padding-right: 0 !important;

        blockquote {
            padding: 20px !important;
        }
    }

    .author-block {

        .author-img {
            width: 50px !important;

            img {
                width: 50px !important;
            }
        }

        .author-content {
            width: 100%!important;
            padding-left: 0 !important;
        }

        // .author-all_content {
        //     width: 50px !important;
        // }
    }

    .search input#search {
        padding: 13px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: -5px;
        width: 67%;
        border: 1px solid #f98653;
    }

    .search .btn {
        background-color: #f98653;
        color: #fff;
        border: 1px solid #f98653;
        padding: 13px 20px;
        cursor: pointer;
        -webkit-transition: all 0.2s;
        -o-transition: all 0.2s;
        transition: all 0.2s;
    }

    #comment-form .form-group {
        flex-direction: column;
    }

    #newsPage .item-news .author-block .author-content .last-content ul li a {
        white-space: normal !important;
        margin-bottom: 10px !important;
    }

    #newsPage .item-news .author-block .author-all_content {
        width: 100% !important;
        text-align: left !important;
    }

    .author-block {
        flex-direction: column;
    }

    #comment-form-placeholder #comment-form .form-group label {
        width: 100% !important;
        margin-bottom: 10px;
    }

    #comment-form-placeholder #comment-form .form-group input, #comment-form-placeholder #comment-form .form-group textarea {
        width: calc(100% - 30px) !important;
        margin-left: 0!important;
    }

}