.comments {
    margin-top: 50px;
}

#comment-form-placeholder {
    #comment-form {

        .form-group {
            display: flex;
            margin-bottom: 20px;
            align-items: center;

            label {
                width: 250px;
            }

            input, textarea {
                width: 100%;
                margin-left: 30px;
                padding: 15px;
                border: 1px solid $orange;
            }

            textarea {
                &:focus {
                    border: 1px solid #4caf50 !important;
                }

                &:focus-visible {
                    border: none;
                    outline: none;
                }
            }
        }

        .form-actions {
            display: flex;
            justify-content: right;

            .btn {
                padding: 10px 20px;
                margin-left: 10px;
                background-color: $orange;
                border: 1px solid $orange;
                color: #fff;
                cursor: pointer;
                transition: all .3s;

                &:hover {
                    background-color: #fff;
                    color: $orange;
                    transition: all .3s;
                }
            }
        }
    }
}