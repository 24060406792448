.container-auth-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

    .login-block {
        padding: 100px;
        border-right: 1px solid #f98653;

        .panel-heading {
            font-size:36px;
            margin-bottom: 25px;
            color: #f98653;
        }

        .forget-password {
            width: 100%;
            padding-top: 15px;
            border-top: 1px solid #f98653;
        }
    }

    .register-block {
        padding: 100px;

        .reqblock {
            width: 100%;
            padding-top: 15px;
            border-top: 1px solid #f98653;
        }
    }

    input {
        padding: 13px;
        margin-bottom: 15px;
        border: 1px solid #f98653;
    }

    input[type="submit"] {
        width: 100%;
        background-color: #f98653;
        color: #fff;
        border: 1px solid #f98653;
        padding: 13px 0;
        cursor: pointer;
        transition: $transition;

        &:hover {
            color: #f98653;
            background-color: #fff;
            transition: $transition;
        }
    }
}