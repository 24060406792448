footer {
    background-color: $gray;
    // margin-top: 100px;
    color: #fff;

    .container {
        padding: 100px 0 50px;
        display: block;

        .menu-blocks {
            margin-bottom: 50px;
            display: flex;
            justify-content: space-between;

            .menu-item {
                ul {
                    li {
                        list-style: none;
                        line-height: 30px;

                        a {
                            color: #fff;

                            &:hover {
                                color: $orange;
                            }
                        }
                    }
                }
            }
        }

        .copyright {
            text-align: center;
            font-size: 13px;
            position: relative;
            
            span {
                font-size: 10px;
                position: absolute;
                top: -5px;
            }
        }
    }

    #subscribe {
        width: 100%;
        padding: 100px 0;
        text-align: center;
        background: rgb(249,134,83);
        background: radial-gradient(circle, rgba(249,134,83,1) 0%, rgba(172,56,5,1) 100%);
        
        p {
            color: #fff;
            margin-bottom: 40px;

            &.header {
                font-size: 24px;
                margin-bottom: 25px;
            }
        }

		input {
			padding: 13px;
			margin-right: -5px;
			min-width: 300px;
			width: 300px;
			border: 1px solid #fff;
		}

		.btn {
			background-color: $orange;
			color: #fff;
			border: 1px solid #fff;
			padding: 13px 020px;
			cursor: pointer;
			transition: $transition;
	
			&:hover {
				color: $orange;
				background-color: #fff;
				border-left: 1px solid $orange;
				transition: $transition;
			}
		}
    }
}