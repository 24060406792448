#Page503 {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .errorBlock {
        text-align: center;
        h1 {
            font-size: 10rem;
            margin-bottom: 50px;
            color: $orange;
        }
    
        p {
            font-size: 1rem;
            line-height: 1.8rem;
            margin-bottom: 25px;

            &.h2 {
                font-size: 2rem;
                font-weight: bold;
            }
        }
    }
}