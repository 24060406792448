#newsPage {
    .container {
        .item-news {
            flex: 7;
            padding-right: 50px;
            margin-bottom: 100px;
            
            h1 {
                margin: 20px 0 10px;
            }
        
            ul, ol {
                // list-style-type: none;
                margin-left: 50px;
                margin-bottom: 30px;
                line-height: 24px;
            }
        
            p {
                margin: 20px 0;
                line-height: 24px;
            }

            .item-news-info {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
    
                .category-date {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .category {
                        a {
                            color: $orange;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .date {
                        margin-left: 20px;
                    }
                }

                .social {
                    display: flex;

                    a {
                        position: relative;
                        display: block;
                        width: 36px;
                        height: 36px;
                        margin-left: 10px;
                        border-radius: 50%;
                        background-color: $orange;
                        border: solid 1px $orange;
                        color: #fff;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 7px;
                            left: 7px;
                            width: 22px;
                            height: 22px;
                            mask-size: contain;
                            mask-position: center;
                            mask-repeat: no-repeat;
                            background-color: #fff;
                        }

                        &:hover {
                            background-color: #fff;
                            color: $orange;

                            &::after {
                                background-color: $orange;
                            }
                        }

                        &.telegram {
                            &::after {
                                left: 5px;
                                mask-image: url(/assets/template/images/icons/telegram.svg);
                            }
                        }

                        &.vk {
                            &::after {
                                mask-image: url(/assets/template/images/icons/vk.svg);
                            }
                        }

                        &.email {
                            &::after {
                                mask-image: url(/assets/template/images/icons/mail.svg);
                            }
                        }
                    }
                }
            }
            .news-image {
                img {
                    width: 100%;
                }
            }

            a {
                color: $orange;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            blockquote {
                background-color: $orange;
                color: #fff;
                padding: 100px;
                margin-bottom: 50px;
                p {
                    margin: 0;
                    padding: 0;
                    color: #fff;

                    em {
                        color: #fff;
                    }
                }

                a {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }
    }
}

#pdopage {
    margin-top: 50px;
    border-top: 1px solid #eee;

    .rows {
        margin-bottom: 50px;

        .news_block_item {
            width: 100%;
            // border: #f98653 solid 1px;
            border-bottom: 1px solid #eee;
            padding-bottom: 50px;
            margin: 50px 0;
            display: flex;

            .nbi_image {

                img {
                    height: 200px;
                }
            }

            .nbi_info {
                padding-left: 30px;
                
                .nbi_title {
                    margin-bottom: 50px;
                    font-weight: 700;
                }

                .nbi_text {
                    margin-bottom: 50px;
                }
    
                .nbi_row {
                    display: flex;
                    justify-content: space-between;

                    font-size: 11px;
                    font-weight: 600;

                    .nbi_category {
                        a {
                            font-weight: 400;
                            color: #f98653;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .btn-more {
        padding: 20px;
        color: #fff;
        background-color: #f98653;
        border: 1px solid #f98653;
        width: 140px;
        display: block;
        margin: 0 auto;
        cursor: pointer;
        transition: all .3s;
        white-space: nowrap;

        &:hover {
            background-color: #fff;
            color: #f98653;
            transition: all .3s;
        }
    }
}