#Page401 {
    width: 100%;
    margin-top: 100px;
    text-align: center;
    h1 {
        font-size: 7.5em;
        margin-bottom: 0px;
        color: $orange;
    }

    p {
        font-size: 3.7em;
    }
}